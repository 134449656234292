import React from 'react';
import '../../App.css';
import ProductCards from '../ProductCards';


export default function Products() {
  return (
    <>
      <div className='products'>
        <h1 className="title">
          Produkter
        </h1>
      </div>
      <div className="text-field">

      </div>
      <ProductCards/>
    </>
  )
}
