import React from 'react';

import '../../App.css';
import ProjectCards from "../ProjectCards";


export default function Projects() {

    return (
        
        <>
            <div className='projects'>
                <h1 className="title">
                    Projekt
        </h1>
            </div>
            <div className="text-field">
            </div>
            <ProjectCards />
        </>
    )
}

